import React, { useEffect, useState } from 'react'
import Input from 'assets/components/Input'
import { Link, useRouteMatch } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store'
import {
	setClearError,
	setError,
	startAdminSetAvailableRoles,
	startCreateRole,
	startRoleSetSelected,
	setClearSelectedRole,
	startEditRole
} from 'store/slices/admin'

const sections = [
	{
		section: 'admin',
		name: 'админ',
		viewName: 'view_admins',
		editName: 'edit_admins'
	},
	{
		section: 'domain',
		name: 'домены',
		viewName: 'view_domains',
		editName: 'edit_domains'
	},
	{
		section: 'file',
		name: 'файлы',
		viewName: 'view_files',
		editName: 'edit_files'
	},
	{
		section: 'gateway',
		name: 'шлюзы',
		viewName: 'view_gateways',
		editName: 'edit_gateways',
		moveName: 'move_gateways'
	},
	{
		section: 'order',
		name: 'заказы',
		viewName: 'view_orders',
		editName: '',
		transferName: 'transfer_orders'
	},
	{
		section: 'product',
		name: 'продукты',
		viewName: 'view_products',
		editName: 'edit_products'
	},
	{ section: 'stats', name: 'dashboard', viewName: 'view_stats', editName: '' },
	{
		section: 'user',
		name: 'клиенты',
		viewName: 'view_users',
		editName: 'edit_users'
	},
	{
		section: 'analytics',
		name: 'аналитика',
		viewName: 'view_analytics',
		editName: ''
	},
	{
		section: 'tags',
		name: 'таги',
		viewName: 'view_tags',
		editName: 'edit_tags'
	},
	{
		section: 'payment',
		name: 'платежи',
		refundName: 'refund_payments',
		chargebackName: 'chargeback_payments',
		createName: 'create_payments'
	},
	{
		section: 'shaker',
		name: 'шейкер',
		viewName: 'view_shakers',
		editName: 'edit_shakers'
	},
	{
		section: 'company',
		name: 'юрлица',
		viewName: 'view_companies',
		editName: 'edit_companies'
	},
	{
		section: 'bank_issuer',
		name: 'банки',
		viewName: 'view_banks',
		editName: 'edit_banks'
	},
	{
		section: 'sberalert',
		name: 'сбералерт',
		viewName: 'view_sberalerts',
		editName: 'edit_sberalerts'
	},
	{
		section: 'admin_logs',
		name: 'логи',
		viewName: 'view_admin_logs',
		editName: ''
	}
]

const RoleCrtPage = () => {
	const dispatch = useAppDispatch()
	const {
		params: { id }
	} = useRouteMatch()

	const errors = useAppSelector(state => state.admin.errors)
	const availableRoles = useAppSelector(state => state.admin.availableRoles)
	const selectedRole = useAppSelector(state => state.admin.selectedRole)
	const availableEdit = useAppSelector(state =>
		state.auth.role.permissions.admin.includes('edit_admins')
	)

	const [name, setName] = useState('')
	const [permissions, setPermissions] = useState({
		admin: [],
		domain: [],
		file: [],
		gateway: [],
		order: [],
		product: [],
		stats: [],
		user: [],
		analytics: [],
		tags: [],
		payment: [],
		shaker: [],
		sberalert: [],
		admin_logs: [],
		bank_issuer: []
	})

	useEffect(() => {
		if (availableRoles.length === 0) {
			dispatch(startAdminSetAvailableRoles())
		}
	}, [availableRoles, startAdminSetAvailableRoles])

	// set seletced role on mount
	useEffect(() => {
		if (id) {
			dispatch(startRoleSetSelected(id))
		}
	}, [id, startRoleSetSelected])

	// set selected role name
	useEffect(() => {
		if (selectedRole) {
			setName(selectedRole.name)
			setPermissions(prev => ({ ...prev, ...selectedRole.permissions }))
		}
	}, [selectedRole])

	//cleanup
	useEffect(
		() => () => {
			dispatch(setClearSelectedRole())
			dispatch(setClearError())
		},
		[setClearError, setClearSelectedRole]
	)

	const onChangeCheckbox = (name, permission) => {
		let section = sections.filter(i => i.section === name)

		if (permission === 'view') {
			const str = addPluralString(name)
			const role = section.length ? section[0].viewName : `view_${str}`

			if (!permissions[name]) {
				permissions[name] = []
			}

			permissions[name].includes(role)
				? setPermissions({
						...permissions,
						[name]: permissions[name].filter(item => item !== role)
				  })
				: setPermissions({
						...permissions,
						[name]: [...permissions[name], role]
				  })
		}

		if (permission === 'edit') {
			const str = addPluralString(name)
			const role = section.length ? section[0].editName : `edit_${str}`

			if (!permissions[name]) {
				permissions[name] = []
			}

			permissions[name].includes(role)
				? setPermissions({
						...permissions,
						[name]: permissions[name].filter(item => item !== role)
				  })
				: setPermissions({
						...permissions,
						[name]: [...permissions[name], role]
				  })
		}

		if (permission === 'refund') {
			const str = addPluralString(name)

			permissions[name].includes(`refund_${str}`)
				? setPermissions({
						...permissions,
						[name]: permissions[name].filter(item => item !== `refund_${str}`)
				  })
				: setPermissions({
						...permissions,
						[name]: [...permissions[name], `refund_${str}`]
				  })
		}

		if (permission === 'chargeback') {
			const str = addPluralString(name)

			permissions[name].includes(`chargeback_${str}`)
				? setPermissions({
						...permissions,
						[name]: permissions[name].filter(
							item => item !== `chargeback_${str}`
						)
				  })
				: setPermissions({
						...permissions,
						[name]: [...permissions[name], `chargeback_${str}`]
				  })
		}

		if (permission === 'create') {
			const str = addPluralString(name)

			permissions[name].includes(`create_${str}`)
				? setPermissions({
						...permissions,
						[name]: permissions[name].filter(item => item !== `create_${str}`)
				  })
				: setPermissions({
						...permissions,
						[name]: [...permissions[name], `create_${str}`]
				  })
		}

		if (permission === 'transfer') {
			const str = addPluralString(name)

			permissions[name].includes(`transfer_${str}`)
				? setPermissions({
						...permissions,
						[name]: permissions[name].filter(item => item !== `transfer_${str}`)
				  })
				: setPermissions({
						...permissions,
						[name]: [...permissions[name], `transfer_${str}`]
				  })
		}

		if (permission === 'move') {
			const str = addPluralString(name)

			permissions[name].includes(`move_${str}`)
				? setPermissions({
						...permissions,
						[name]: permissions[name].filter(item => item !== `move_${str}`)
				  })
				: setPermissions({
						...permissions,
						[name]: [...permissions[name], `move_${str}`]
				  })
		}
	}

	const addPluralString = name => {
		const sentenceLength = name.length
		return name[sentenceLength - 1] === 's' ? name : name + 's'
	}

	const handleSubmit = () => {
		if (selectedRole) {
			return availableEdit
				? dispatch(startEditRole(id, { name, permissions }))
				: dispatch(setError({ message: 'Нет доступа редактирования' }))
		}
		dispatch(startCreateRole({ name, permissions }))
	}
	return (
		<div className='role-create'>
			<h2 className='role-create__title'>
				{id ? `Редактирование роли` : 'Создать роль'}
			</h2>
			<Input
				placeholder='Название роли'
				type='text'
				name='name'
				inputCls='role-create__input'
				label='Название роли'
				// error={errors.email}
				onChange={({ target: { value } }) => setName(value)}
				value={name}
			/>
			<label className='input__label'>Доступы</label>
			{/* create */}
			{!id &&
				availableRoles.length > 0 &&
				availableRoles.map((item, idx) => {
					const name = Object.keys(item)[0]
					const str = addPluralString(name)
					const section = sections.filter(i => i.section === name)
					const viewRole = section.length ? section[0].viewName : `view_${str}`
					const editRole = section.length ? section[0].editName : `edit_${str}`

					return (
						<div className='create__roles' key={idx.toString()}>
							<span className='create__roles__title'>
								{sections[sections.findIndex(el => el.section === name)]?.name}
							</span>
							{sections[sections.findIndex(el => el.section === name)]?.name !==
								'платежи' && (
								<span className='create__roles__input'>
									<label>Чтение</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'view')}
										checked={permissions[name]?.includes(viewRole)}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'платежи' && (
								<span className='create__roles__input'>
									<label>Возврат</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'refund')}
										checked={permissions[name]?.includes(`refund_${str}`)}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'платежи' && (
								<span className='create__roles__input'>
									<label>Чарджбэк</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'chargeback')}
										checked={permissions[name]?.includes(`chargeback_${str}`)}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'платежи' && (
								<span className='create__roles__input'>
									<label>Создание</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'create')}
										checked={permissions[name]?.includes(`create_${str}`)}
									/>
								</span>
							)}
							{item[name].length >= 2 &&
								sections[sections.findIndex(el => el.section === name)]
									?.name !== 'платежи' && (
									<span className='create__roles__input'>
										<label>Запись</label>
										<input
											type='checkbox'
											onChange={() => onChangeCheckbox(name, 'edit')}
											checked={permissions[name]?.includes(editRole)}
										/>
									</span>
								)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'шлюзы' && (
								<span className='create__roles__input'>
									<label>Перенос</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'move')}
										checked={permissions[name]?.includes(`move_${str}`)}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'заказы' && (
								<span className='create__roles__input'>
									<label>Перенос</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'transfer')}
										checked={permissions[name]?.includes(`transfer_${str}`)}
									/>
								</span>
							)}
						</div>
					)
				})}
			{/* edit */}
			{/*{id && selectedRole && availableRoles.length > 0 && Object.keys(selectedRole.permissions).map((item, idx) => {*/}
			{/*  const str = addPluralString(item);*/}
			{/*  return (*/}
			{/*    <div className='create__roles' key={idx.toString()}>*/}
			{/*      <span className='create__roles__title'>{sections[sections.findIndex((el) => el.section === item)].name}</span>*/}
			{/*      <span className='create__roles__input'>*/}
			{/*        <label>Чтение</label>*/}
			{/*        <input*/}
			{/*          type="checkbox"*/}
			{/*          onChange={() => onChangeCheckbox(item, 'view')}*/}
			{/*          checked={permissions[item].includes(`view_${str}`)}*/}
			{/*        />*/}
			{/*      </span>*/}
			{/*      {*/}
			{/*        availableRoles[availableRoles.findIndex((el) => Object.keys(el)[0] === item)][item].length === 2 && (*/}
			{/*          <span className='create__roles__input'>*/}
			{/*            <label>Запись</label>*/}
			{/*            <input*/}
			{/*              type="checkbox"*/}
			{/*              onChange={() => onChangeCheckbox(item, 'edit')}*/}
			{/*              checked={permissions[item].includes(`edit_${str}`)}*/}
			{/*            />*/}
			{/*          </span>*/}
			{/*        )*/}
			{/*      }*/}
			{/*    </div>*/}
			{/*  )*/}
			{/*})}*/}
			{id &&
				selectedRole &&
				availableRoles.length > 0 &&
				availableRoles.map((item, idx) => {
					const name = Object.keys(item)[0]
					const str = addPluralString(name)
					const section = sections.filter(i => i.section === name)
					const viewRole = section.length ? section[0].viewName : `view_${str}`
					const editRole = section.length ? section[0].editName : `edit_${str}`

					return (
						<div className='create__roles' key={idx.toString()}>
							<span className='create__roles__title'>
								{sections[sections.findIndex(el => el.section === name)]?.name}
							</span>
							{sections[sections.findIndex(el => el.section === name)]?.name !==
								'платежи' && (
								<span className='create__roles__input'>
									<label>Чтение</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'view')}
										checked={
											permissions[name] !== undefined &&
											permissions[name].includes(viewRole)
										}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'платежи' && (
								<span className='create__roles__input'>
									<label>Возврат</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'refund')}
										checked={
											permissions[name] !== undefined &&
											permissions[name]?.includes(`refund_${str}`)
										}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'платежи' && (
								<span className='create__roles__input'>
									<label>Чарджбэк</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'chargeback')}
										checked={
											permissions[name] !== undefined &&
											permissions[name]?.includes(`chargeback_${str}`)
										}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'платежи' && (
								<span className='create__roles__input'>
									<label>Создание</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'create')}
										checked={
											permissions[name] !== undefined &&
											permissions[name]?.includes(`create_${str}`)
										}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]
								?.editName &&
								availableRoles[
									availableRoles.findIndex(el => Object.keys(el)[0] === name)
								][name].length >= 2 && (
									<span className='create__roles__input'>
										<label>Запись</label>
										<input
											type='checkbox'
											onChange={() => onChangeCheckbox(name, 'edit')}
											checked={
												permissions[name] !== undefined &&
												permissions[name].includes(editRole)
											}
										/>
									</span>
								)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'шлюзы' && (
								<span className='create__roles__input'>
									<label>Перенос</label>
									<input
										type='checkbox'
										onChange={() => onChangeCheckbox(name, 'move')}
										checked={
											permissions[name] !== undefined &&
											permissions[name]?.includes(`move_${str}`)
										}
									/>
								</span>
							)}
							{sections[sections.findIndex(el => el.section === name)]?.name ===
								'заказы' && (
								<span className='create__roles__input'>
									<label>Перенос</label>
									<input
										type='checkbox'
										onChange={() => {
											onChangeCheckbox(name, 'transfer')
										}}
										checked={
											permissions[name] !== undefined &&
											permissions[name]?.includes(`transfer_${str}`)
										}
									/>
								</span>
							)}
						</div>
					)
				})}
			<button className='button' onClick={handleSubmit}>
				Сохранить
			</button>
			<Link className='role-create__prev' to='/admin/roles'>
				Назад к списку
			</Link>
			{errors.message && <div style={{ color: 'red' }}>{errors.message}</div>}
		</div>
	)
}

export default RoleCrtPage
