// import { Link } from 'react-router-dom'
import cls from '../ErrorsChains/ErrorsChainsTable.module.scss'
import { useAppDispatch } from 'store'
import {
	setSortErrorListCount,
	setSortErrorListDate
} from 'store/slices/errors'
import { ReactComponent as Sort } from 'assets/img/sort.svg'

export const ErrorsTable = ({ errorsList }) => {
	const headers = [
		{
			name: 'дата',
			onClick: () => dispatch(setSortErrorListDate())
		},
		{
			name: 'ОШИБКА'
		},
		{
			name: 'количество ошибок',
			onClick: () => dispatch(setSortErrorListCount())
		}
	]

	const dispatch = useAppDispatch()

	return (
		<table className={cls.ErrorsChainsTable}>
			<tr>
				{headers.map(el => (
					<th onClick={el?.onClick}>
						{el.name.toUpperCase()}{' '}
						{el?.onClick && (
							<span
								style={{
									width: '20px',
									display: 'inline-block',
									marginLeft: '20px'
								}}
							>
								<Sort />
							</span>
						)}
					</th>
				))}
			</tr>
			{errorsList?.map(el => (
				<tr>
					<td>{el.created_at.slice(0, 10).split('-').reverse().join('.')}</td>
					<td>{`${el.err_message} (${el.error_code})`}</td>
					<td>{el.err_count}</td>
				</tr>
			))}
		</table>
	)
}
