import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import Filters from 'assets/components/Filters'
import Table from 'assets/components/Table'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { getAllGateways } from 'store/slices/gateway'
import { companyGetAll } from 'store/slices/company'
import { productGetAll } from 'store/slices/product'
import { getPaymentErrorListStatus } from 'store/slices/order'
import { ErrorsTable } from './ErrorsTable'
import {
	getErrorsList,
	setErrorsFilter,
	setErrorsPage
} from 'store/slices/errors'

const titlesErrorsTop = [
	{
		name: 'дата',
		onClick: true,
		title: 'titlesErrorsTopDate'
	},
	{
		name: 'ОШИБКА'
	},
	{
		name: 'количество ошибок',
		onClick: true,
		title: 'titlesErrorsTopCountError'
	},
	{
		name: 'шлюз'
	},
	{
		name: 'домен'
	},
	{
		name: 'ID веба'
	},
	{
		name: 'продукт',
		mod: 'big'
	},
	{
		name: 'юр лицо'
	},
	{
		name: 'банк'
	}
]

const ErrorsTop = () => {
	const dispatch = useAppDispatch()
	const gateways = useAppSelector(state => state.gateway.gateways)
	const errorsList = useAppSelector(state => state.errors.errorsList)
	const appliedFilters = useAppSelector(state => state.errors.filters)
	const limit = useAppSelector(state => state.errors.pagination.limit)
	const page = useAppSelector(state => state.errors.pagination.page)
	const total = useAppSelector(state => state.errors.pagination.total)
	const companies = useAppSelector(state => state.company.list)
	const products = useAppSelector(state => state.product.list)
	const [productsObject, setProductsObject] = useState({})
	const [companiesObject, setCompaniesObject] = useState({})
	const [gatewaysObject, setGatewaysObject] = useState({})
	const [paymentSystem, setPaymentSystem] = useState([])
	const [productsListOption, setProductsListOption] = useState([])
	const [companiesOption, setCompaniesOption] = useState([])
	const paymentErrorList = useAppSelector(
		state => state.order.paymentErrorList
	)?.map(item => ({
		label: item,
		value: item
	}))
	const [reloadPage, setReloadPage] = useState(true)

	useEffect(() => {
		setGatewaysObject(
			gateways.reduce((acc, obj) => {
				const key = obj.id
				return {
					...acc,
					[key]: { ...obj }
				}
			}, {})
		)
	}, [gateways])

	useEffect(() => {
		setProductsObject(
			products.reduce((acc, obj) => {
				const key = obj.id
				return {
					...acc,
					[key]: { ...obj }
				}
			}, {})
		)
	}, [products])

	useEffect(() => {
		setCompaniesObject(
			companies.reduce((acc, obj) => {
				const key = obj.id
				return {
					...acc,
					[key]: { ...obj }
				}
			}, {})
		)
	}, [companies])

	useEffect(() => {
		setCompaniesOption(
			companies.map(item => ({
				value: item.id,
				label: item.name
			}))
		)
	}, [companies])

	useEffect(() => {
		products.length &&
			setProductsListOption(
				products
					.filter(item => item.innerName !== '')
					.reverse()
					.map(option => ({ label: option.innerName, value: option.id }))
			)
	}, [products])

	useEffect(() => {
		dispatch(getAllGateways())
		dispatch(companyGetAll())
		dispatch(productGetAll())
		dispatch(getPaymentErrorListStatus())
	}, [])

	useEffect(() => {
		const handleGetErrorsList = async () => {
			let params = {}
			for (let key in appliedFilters) {
				if (appliedFilters[key]) {
					params[`filter[${key}]`] = await appliedFilters[key]
				}
			}
			params.limit = limit
			params.page = page

			dispatch(getErrorsList(params))
		}
		if (reloadPage) {
			handleGetErrorsList()
			setReloadPage(false)
		}
	}, [reloadPage])

	useEffect(() => {
		gateways?.length > 1 &&
			setPaymentSystem(
				gateways
					.map(el => ({
						label: `${el.paymentSystem}: ${el.name} (${el.companyName})`,
						value: el.id,
						paymentSystem: el.paymentSystem,
						name: el.name,
						id: el.id,
						companyName: el.companyName
					}))
					.sort((a, b) => (a.label > b.label ? 1 : -1))
			)
	}, [gateways])

	const handlePageChange = current => {
		dispatch(setErrorsPage(current))
		setReloadPage(true)
	}

	const formatOptionLabel = ({ paymentSystem, name, id, companyName }) => (
		<div>
			<div>
				<strong>ID: </strong>
				{id}
			</div>
			<div>
				<strong>Платёжная система: </strong>
				{paymentSystem}
			</div>
			<div>
				<strong>Платёжный шлюз: </strong>
				{name}
			</div>
			<div>
				<strong>ЮрЛицо: </strong>
				{companyName ? companyName : '—'}
			</div>
		</div>
	)

	return (
		<>
			<Filters
				topErrors={true}
				appliedFilters={{ ...appliedFilters, limit }}
				onFilterChange={obj => {
					dispatch(setErrorsFilter(obj))
					setReloadPage(true)
				}}
				orderLabels={[
					{ label: 'Билл', value: '0' },
					{ label: 'Ребилл', value: '2' }
				]}
				formatOptionLabel={formatOptionLabel}
				gatewaysOption={paymentSystem}
				companiesOption={companiesOption}
				multiSelectOptions={productsListOption}
				paymentErrorListOption={paymentErrorList}
			/>
			<ErrorsTable errorsList={errorsList} />
			<Pagination
				className='order__pagination'
				current={page}
				total={total}
				pageSize={limit}
				onChange={handlePageChange}
				showTitle={false}
			/>
		</>
	)
}

export default ErrorsTop
