import cls from 'pages/ErrorsPage/ErrorsChains/ErrorsChainsTable.module.scss'
import { FormattedNumber } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cn from 'classnames'

const headers = [
	{
		name: 'ID'
	},
	{
		name: 'ID шлюза'
	},
	{
		name: 'номер'
	},
	{
		name: 'email'
	},
	{
		name: 'продукт'
	},
	{
		name: 'сумма'
	},
	{
		name: 'статус'
	},
	{
		name: 'домен'
	},
	{
		name: 'cpa'
	},
	{
		name: 'webmaster'
	},
	{
		name: 'создан'
	}
]

export const OrderAllTable = ({
	ordersList,
	isCheckAll,
	handleClickCheckSelectAll,
	handleClickCheck,
	isCheck,
	handleClickStartEdit
}) => {
	const availableEdit = useSelector(
		state => state.auth.role.permissions.payment
	)?.includes('create_payments')

	return (
		<div className={cls.errors}>
			{availableEdit && (
				<button
					className={cls.btn}
					disabled={!isCheck.length}
					onClick={handleClickStartEdit}
				>
					Массовый платёж
				</button>
			)}
			<table className={cls.ErrorsChainsTable}>
				<tr>
					{availableEdit && (
						<th>
							<input
								type='checkbox'
								name='selectAll'
								onChange={handleClickCheckSelectAll}
								checked={isCheckAll}
							/>
						</th>
					)}
					{headers.map(el => (
						<>
							<th onClick={el?.onClick}>{el.name.toUpperCase()} </th>
						</>
					))}
				</tr>
				{ordersList?.map(el => (
					<tr>
						{availableEdit && (
							<td>
								<input
									key={el.id}
									type='checkbox'
									name={el.name}
									id={el.id}
									onChange={handleClickCheck}
									checked={isCheck.includes(el.id)}
								/>
							</td>
						)}
						<td>{el.id}</td>
						<td>
							<Link to={`/gateway/edit/${el.gateway.id}`} target='_blank'>
								{el.gateway.id}
							</Link>
						</td>
						<td>
							<Link to={`/order/${el.id}`} target='_blank'>
								{el.number}
							</Link>
						</td>
						<td>{el.user.email}</td>
						<td>
							<Link
								to={`/product/edit/${el?.productId}`}
								target='_blank'
								className='tableRow__link'
							>
								{el?.productId}
							</Link>
						</td>
						<td className={cls.currency}>
							<FormattedNumber
								value={el.total}
								style='currency'
								currency={el.currency}
							/>
						</td>
						<td
							className={cn({
								[cls.active]: el.status === 'Активный',
								[cls.unactive]: el.status === 'Неактивный'
							})}
						>
							{el.status}
						</td>
						<td>{el.domain}</td>
						<td>{el.cpa}</td>
						<td>{JSON.parse(el.webmaster)}</td>
						<td>{el.createdAt}</td>
					</tr>
				))}
			</table>
		</div>
	)
}
