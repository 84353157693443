import { Link } from 'react-router-dom'
import cls from './ErrorsChainsTable.module.scss'
import { useAppDispatch } from 'store'
import cn from 'classnames'
import {
	setSortChainErrorListDate,
	setSortChainErrorListDateAndTime
} from 'store/slices/errors'
import { ReactComponent as Sort } from 'assets/img/sort.svg'

const paymentsStatus = t => {
	switch (t) {
		case '0':
			return 'Новый'
		case '1':
			return 'Успешный'
		case '2':
			return 'Неуспешный'
		default:
			return t
	}
}

export const ErrorsChainsTable = ({
	errorsList,
	isCheckAll,
	handleClickCheckSelectAll,
	handleClickCheck,
	isCheck,
	handleClickStartEdit
}) => {
	const dispatch = useAppDispatch()
	const headers = [
		{
			name: 'дата',
			onClick: () => dispatch(setSortChainErrorListDate())
		},
		{
			name: 'ID шлюза'
		},
		{
			name: 'ID заказа'
		},
		{
			name: 'Банк'
		},
		{
			name: 'статус'
		},
		{
			name: 'дата и время',
			onClick: () => dispatch(setSortChainErrorListDateAndTime())
		},
		{
			name: 'сумма и ответ',
			mod: 'big'
		}
	]

	return (
		<div className={cls.errors}>
			<button
				className={cls.btn}
				disabled={!isCheck.length}
				onClick={handleClickStartEdit}
			>
				Массовый платёж
			</button>
			<table className={cls.ErrorsChainsTable}>
				<tr>
					<th>
						<input
							type='checkbox'
							name='selectAll'
							onChange={handleClickCheckSelectAll}
							checked={isCheckAll}
						/>
					</th>
					{headers.map(el => (
						<>
							<th onClick={el?.onClick}>
								{el.name.toUpperCase()}{' '}
								{el?.onClick && (
									<span
										style={{
											width: '20px',
											display: 'inline-block',
											marginLeft: '20px'
										}}
									>
										<Sort />
									</span>
								)}
							</th>
						</>
					))}
				</tr>
				{errorsList?.map(el => (
					<tr>
						<td>
							<input
								key={el.id}
								type='checkbox'
								name={el.name}
								id={el.id}
								onChange={handleClickCheck}
								checked={isCheck.includes(el.id)}
							/>
						</td>
						<td>{el.created_at.slice(0, 10).split('-').reverse().join('.')}</td>
						<td>
							<Link to={`/gateway/edit/${el.gateway.id}`} target='_blank'>
								{el.gateway.id}
							</Link>
						</td>
						<td>
							<Link to={`/order/${el.id}`} target='_blank'>
								{el.id}
							</Link>
						</td>
						<td>{el.issuer}</td>
						<td
							className={cn({
								// [cls.active]: el.status === 'Активный',
								[cls.unactive]: el.status === 'Неактивный'
							})}
						>
							{el.status}
						</td>
						<td>
							{el.data.map((item, index) => (
								<div key={index}>
									<div>
										{item.op_cr_at.slice(0, 10).split('-').reverse().join('.')}{' '}
										{item.op_cr_at.slice(-8)}
									</div>
									{el.data.length > 1 && <br />}
								</div>
							))}
						</td>
						<td>
							{el.data.map((item, index) => (
								<div key={index}>
									<div>
										{item.error_code && item.error_message
											? `${item.amount}  Ответ: ${paymentsStatus(
													item.status
											  )} ${item.error_message} ; Код ошибки: ${
													item.error_code
											  }`
											: item.error_message
											? `${item.amount}  Ответ: ${paymentsStatus(
													item.status
											  )} ${item.error_message}`
											: `${item.amount} Ответ: ${paymentsStatus(item.status)}`}
									</div>
									{el.data.length > 1 && <br />}
								</div>
							))}
						</td>
					</tr>
				))}
			</table>
		</div>
	)
}
