import React from 'react'
import { useAppSelector } from 'store'
import Header from 'assets/components/Header'
import { LinkTabs } from 'assets/components/LinkTabs/LinkTabs'
import Loader from '../../assets/components/Loader'
import OrderAll from './OrderAll/OrderAll'
import OrderRefund from './OrderRefund/OrderRefund'
import { useParams } from 'react-router-dom'

const OrderPage = () => {
	const isLoad = useAppSelector(state => state.order.isLoad)
	const isLoadRefund = useAppSelector(state => state.refund.isLoad)
	const { tabName } = useParams()
	const { role } = useAppSelector(state => state.auth)

	const list = role?.permissions.order?.includes('transfer_orders')
		? [
				{ name: 'Все заказы', link: '/orders/all', value: 'all' },
				{ name: 'Возврат средств', link: '/orders/refund', value: 'refund' },
				{ name: 'Перенос заказов', link: '/orders/transfer', value: 'transfer' }
		  ]
		: [
				{ name: 'Все заказы', link: '/orders/all', value: 'all' },
				{ name: 'Возврат средств', link: '/orders/refund', value: 'refund' }
		  ]
	return (
		<>
			{isLoad || isLoadRefund ? <Loader /> : null}
			<Header title='Заказы' />
			<LinkTabs list={list} />
			{tabName === 'all' && <OrderAll />}
			{tabName === 'refund' && <OrderRefund />}
		</>
	)
}

export default OrderPage
